export const cmdConstants = {
    CMD_GET_REQUEST: 'CMD_GET_REQUEST',
    CMD_GET_SUCCESS: 'CMD_GET_SUCCESS',
    CMD_GET_FAILURE: 'CMD_GET_FAILURE',
    SET_ORDERS: 'SET_ORDERS',
    GET_ORDERS: 'GET_ORDERS',
    DELETE_ORDERS: 'DELETE_ORDERS',
    CMD_REFRESH: 'CMD_REFRESH',
    CMD_CALENDAR_SCREEN: 'CMD_CALENDAR_SCREEN',
    CMD_SELECTED_ID: 'CMD_SELECTED_ID',
    DATE_SELECTED: 'DATE_SELECTED',
    ACTIVE_KEY: 'ACTIVE_KEY',
    SCROLL_TO: 'SCROLL_TO',
    PAYMENT_INCIDENT_SHIPPING: 'PAYMENT_INCIDENT_SHIPPING',
}
