import axios from "axios";

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'API-TOKEN': process.env.REACT_APP_API_TOKEN},
    validateStatus: function (status) {
        return status < 500;
    }
});

export default AxiosInstance;
